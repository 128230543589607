import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
import cloudBlack from "../../assets/images/sidebar/cloudBlack.svg"
import cloudOrange from "../../assets/images/sidebar/cloudOrange.svg"
import rapidxdIcon from "../../assets/images/sidebar/rapidXD_icon.svg"
import discoverOrange from "../../assets/images/sidebar/DiscoverAtomOrange.svg"
import discoverBlack from "../../assets/images/sidebar/DiscoverAtomBlack.svg"
import MyWorkspaceOrange from "../../assets/images/sidebar/MyWorkspaceOrange.svg"
import FeatureStoreBlack from "../../assets/images/sidebar/FeatureStoreBlack.svg"
import FeatureStoreOrange from "../../assets/images/sidebar/FeatureStoreOrange.svg"
import MyWorkspaceBlack from "../../assets/images/sidebar/MyWorkspaceBlack.svg"
import AdminToolsBlack from "../../assets/images/sidebar/AdminToolsBlack.svg"
import AdminToolsOrange from "../../assets/images/sidebar/AdminToolsOrange.svg"
import automationIntelligenceBlack from "../../assets/images/sidebar/automationIntelligenceBlack.svg"
import automationIntelligenceorange from "../../assets/images/sidebar/automationIntelligenceorange.svg"
import DataMarketplaceOrange from "../../assets/images/sidebar/DataMarketplaceOrange.svg"
import DataMarketplaceBlack from "../../assets/images/sidebar/DataMarketplaceBlack.svg"
import devToolsBlack from "../../assets/images/sidebar/devToolsBlack.svg"
import devToolsOrange from "../../assets/images/sidebar/devToolsOrange.svg"
import DataPlatformBlack from "../../assets/images/sidebar/DataPlatformBlack.svg"
import DataPlatformOrange from "../../assets/images/sidebar/DataPlatformOrange.svg"
// import Azure_Synapse_Black from "../../assets/images/sidebar/Azure_Synapse_Black.svg"
import DataModelOrange from "../../assets/images/sidebar/dataModelOr.svg"
import DataModelBlack from "../../assets/images/sidebar/dataModel.svg"
import dashboardicons from "../../assets/images/dashboard/dashboard-panel-grey.svg"
// import AdminTools from "../../assets/images/AdminTools.svg"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link, NavLink } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { useMsal } from "@azure/msal-react"
import dashboardorange from "../../assets/images/dashboard/dashboard-panel-orange.svg"
const {
  REACT_APP_CLOUD_URL,
  REACT_APP_AZURE_SNAPS_LINK,
  REACT_APP_RAPIDXD_LINK,
} = process.env

const SidebarContent = props => {
  const [id, setId] = React.useState()
  const { accounts } = useMsal()
  let email = ""
  if (accounts && accounts.length) {
    email = accounts[0]["username"]
  }
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  useEffect(() => {
    const pathName = props.location.pathname
    setId(props.location.pathname.split("/").pop())

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        // activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])
  useEffect(() => {
    ref.current.recalculate()
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li>
              <NavLink
                className="nav-link"
                to={`/dashboard`}
                isActive={(_, { pathname }) =>
                  [`/dashboard`].includes(pathname)
                }
              >
                <img
                  src={dashboardicons}
                  alt=""
                  width="27"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={dashboardorange}
                  alt=""
                  width="27"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Dashboard")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={`/moat/solution/1`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [
                    `/moat/home`,
                    `/moat/solution/${id}`,
                    `/moat/provision/overview`,
                    `/new-solution`,
                  ].includes(pathname)
                }
              >
                {/* <i style={{ fontSize: "26px" }} className="mdi mdi-atom"></i> */}
                <img
                  src={discoverBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={discoverOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Discover Atom")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={`/genai`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) => [`/genai`].includes(pathname)}
              >
                {/* <i className="bx bx-gift"></i> */}
                <img
                  src={FeatureStoreBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={FeatureStoreOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Gen AI")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={`/my-workspace`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [
                    `/my-workspace`,
                    `/my-workspace/solutions/create`,
                    `/my-workspace/solutions/grid`,
                    `/my-workspace/solutions/overview/${id}`,
                    `/studioide/${id}`,
                    `/my-workspace/assets/list`,
                    `/my-workspace/assets/upload`,
                    `/my-workspace/artifact/on-boarding`,
                    `/my-workspace/artifact/on-boarding/create`,
                    `/my-workspace/artifact/on-boarding/${id}`,
                  ].includes(pathname)
                }
              >
                {/* <i className="bx bx-user-circle"></i> */}
                <img
                  src={MyWorkspaceBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={MyWorkspaceOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("My Workspace")}</span>
              </NavLink>
            </li>
            <li>
              <a href={REACT_APP_CLOUD_URL} target="_blank" rel="noreferrer">
                <img
                  src={cloudBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />

                <img
                  src={cloudOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />

                <span>Cloudi</span>
              </a>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={`/feature-store/introduction`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [`/feature-store/introduction`].includes(pathname)
                }
              >
                {/* <i className="bx bx-gift"></i> */}
                <img
                  src={FeatureStoreBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={FeatureStoreOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Feature Store")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={`/dev-tools`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [`/dev-tools`, `/dev-tools/cookbooks`].includes(pathname)
                }
              >
                {/* <i className="bx bxl-dev-to"></i> */}
                <img
                  src={devToolsBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={devToolsOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Dev Tools")}</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className="nav-link"
                to={`/dataManagent/main_page`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [`/dataManagent/main_page`].includes(pathname)
                }
              >
                
                <img
                  src={DataPlatformBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={DataPlatformOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />

                <span>{props.t("External Data Platform")}</span>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                className="nav-link"
                to={`/di-tools/`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [
                    `/di-tools/`,
                    `/di-tools/di-list`,
                    `/di-tools/di-data/lease`,
                    `/di-tools/di-data/contract`,
                  ].includes(pathname)
                }
              >
                
                <img
                  src={automationIntelligenceBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={automationIntelligenceorange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Automation Intelligence")}</span>
              </NavLink>
            </li> */}
            <li>
              <a href={REACT_APP_RAPIDXD_LINK} target="_blank" rel="noreferrer">
                <img
                  src={rapidxdIcon}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />

                <img
                  src={rapidxdIcon}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />

                <span>{"rapid{XD}"}</span>
              </a>
            </li>
            {/* <li>
              <NavLink
                className="nav-link"
                to={`/di-tools/`}
                activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [
                    `/di-tools/`,
                    `/di-tools/di-list`,
                    `/di-tools/di-data/lease`,
                    `/di-tools/di-data/contract`,
                  ].includes(pathname)
                }
              >
                
                <img
                  src={automationIntelligenceBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={automationIntelligenceorange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("rapid{XD}")}</span>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                className="nav-link"
                to={`/help`}
                // activeClassName="mm-active"
                isActive={(_, { pathname }) =>
                  [
                    `/help`,
                    `/help/contact`,
                    `/help/feedback`,
                    `/help/tutorial`,
                    `/help/knowledge`,
                  ].includes(pathname)
                }
              >
                <img
                  src={HelpBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={HelpOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Help")}</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className="nav-link"
                to={`/data-market-place`}
                // activeClassName="mm-active"
                // isActive={(_, { pathname }) =>
                //   [
                //     `/help`,
                //     `/help/contact`,
                //     `/help/feedback`,
                //     `/help/tutorial`,
                //     `/help/knowledge`,
                //   ].includes(pathname)
                // }
              >
                {/* <i className="bx bx-help-circle"></i> */}
                <img
                  src={DataMarketplaceBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={DataMarketplaceOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{props.t("Data Marketplace")}</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                className="nav-link"
                to={`/enterprise-data-model/1`}
                isActive={(_, { pathname }) =>
                  [`/enterprise-data-model`].includes(pathname)
                }
              >
                <img
                  src={DataModelBlack}
                  alt=""
                  width="25"
                  className="sideBarBeforeHover m-1"
                />
                <img
                  src={DataModelOrange}
                  alt=""
                  width="25"
                  className="sideBarAfterHover m-1"
                />
                <span>{`Enterprise Data Model Library`}</span>
              </NavLink>
              {/* <a className="nav-link" href={REACT_APP_AZURE_SNAPS_LINK} target="_blank" rel="noreferrer" ></a> */}
            </li>

            <li>
              {props.admin?.length > 0 ? (
                <NavLink
                  className="nav-link"
                  to={`/admin/tools`}
                  // activeClassName="mm-active"
                  isActive={(_, { pathname }) =>
                    [
                      `/admin/tools`,
                      `/admin/tools/infra-management`,
                      `/admin/tools/asset-management`,
                      `/admin/tools/data-management`,
                      `/admin/tools/user-management`,
                      `/admin/tools/artifact-management`,
                      `/admin/tools/solution-management`,
                      `/admin/tools/grievance-management`,
                      `/admin/tools/dataset-management`,
                      `/admin/tools/component-management`,
                    ].includes(pathname)
                  }
                >
                  {/* <i className="bx bx-bulb"></i> */}
                  <img
                    src={AdminToolsBlack}
                    alt=""
                    width="25"
                    className="sideBarBeforeHover m-1"
                  />
                  <img
                    src={AdminToolsOrange}
                    alt=""
                    width="25"
                    className="sideBarAfterHover m-1"
                  />
                  <span>{props.t("Admin Tools")}</span>
                </NavLink>
              ) : null}
            </li>
            {/* <li>
              {props.admin?.length > 0 ? (
                <NavLink
                  className="nav-link"
                  to={`/system-configuration`}
                  
                  isActive={(_, { pathname }) =>
                    [
                      `/system-configuration`,
                      `/system-configuration/manage-env`,
                    ].includes(pathname)
                  }
                >
                  
                  <img
                    src={devToolsBlack}
                    alt=""
                    width="25"
                    className="sideBarBeforeHover m-1"
                  />
                  <img
                    src={devToolsOrange}
                    alt=""
                    width="25"
                    className="sideBarAfterHover m-1"
                  />
                  <span>{props.t("Configuration")}</span>
                </NavLink>
              ) : null}
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
